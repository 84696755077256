<template>
  <v-container class="container-content-base">
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="submit">
          <v-layout column>
            <v-layout>
              <v-layout column style="flex: 50">
                <label class="mr-3 label-dense">
                  {{ $t("name_prices") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  vid="name"
                  :rules="requiredRules"
                  class="w-fluid"
                >
                  <v-text-field
                    ref="namePrice"
                    v-model="name"
                    outlined
                    dense
                    :maxlength="maximumSmallText"
                    :placeholder="$t('place_holders.name_prices')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-layout>
              <v-layout
                v-if="lstPostOffice.length"
                column
                style="flex: 50"
                class="ml-2"
              >
                <label class="mr-3 label-dense">
                  {{ $t("post_offices") }}
                </label>
                <v-text-field
                  v-if="checkReadonly"
                  v-model="postOfficeName"
                  :disabled="checkReadonly"
                  :filled="checkReadonly"
                  class="rounded-lg"
                  outlined
                  dense
                />
                <div v-else>
                  <v-autocomplete
                    v-model="selectPostOffice"
                    outlined
                    dense
                    class="rounded-lg"
                    item-text="name"
                    item-value="id"
                    :no-data-text="$t('no_data')"
                    :disabled="checkReadonly"
                    :filled="checkReadonly"
                    :items="lstPostOffice"
                    :placeholder="$t('place_holders.choice-post-office')"
                  />
                </div>
              </v-layout>
            </v-layout>
            <v-layout class="d-flex mt-3">
              <v-layout column style="flex: 50">
                <label class="mr-3 label-dense">
                  {{ $t("group-shipping") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="textRequired"
                    :rules="requiredRules"
                  >
                    <v-select
                      v-model="partnerSelect"
                      outlined
                      dense
                      class="rounded-lg"
                      item-text="partnerName"
                      item-value="partnerId"
                      :disabled="checkReadonly"
                      :filled="checkReadonly"
                      :items="lstPartner"
                      :placeholder="$t('place_holders.choice_group_shipping')"
                      :error-messages="errors"
                      @change="onChangeGetServiceAll"
                    />
                  </validation-provider>
                </div>
              </v-layout>
              <div style="flex: 50" class="ml-2">
                <label class="mr-3 label-dense">
                  {{ $t("ship_service") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="textRequired"
                    :rules="requiredRules"
                  >
                    <v-select
                      v-model="serviceSelect"
                      outlined
                      dense
                      class="rounded-lg"
                      item-text="serviceDescription"
                      item-value="serviceId"
                      hide-details
                      :disabled="!partnerSelect || checkReadonly"
                      :filled="!partnerSelect || checkReadonly"
                      :items="lstService"
                      :placeholder="$t('place_holders.ship_service')"
                      :error-messages="errors"
                      :style="!partnerSelect ? 'background: #EBEBEB' : ''"
                    />
                  </validation-provider>
                </div>
              </div>
            </v-layout>
            <v-layout class="d-flex ml-2" align-center>
              <v-switch v-model="isDefaul" inset :disabled="checkReadonly" />
              <span>
                {{
                  checkReadonly ? $t("price-default") : $t("set-price-default")
                }}
              </span>
            </v-layout>
            <v-layout class="border-primary rounded px-2 py-3">
              <v-layout column>
                <table class="table-price-list">
                  <HeaderPricePartner />
                  <tbody>
                    <InputPricePartner
                      v-for="(item, index) in lstPrice"
                      :key="item.vId"
                      :item="item"
                      :idx="index"
                      :on-blur-weight="onBlurWeight"
                      :on-delete-item="onDeleteItem"
                    />
                  </tbody>
                  <tfoot>
                    <v-icon
                      class="ml-4"
                      color="primary"
                      size="25"
                      @click="addItem(false, false)"
                    >
                      mdi-plus-circle-outline
                    </v-icon>
                  </tfoot>
                </table>
              </v-layout>
            </v-layout>
            <v-layout justify-end class="mt-3">
              <v-btn @click="cancelData">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                type="submit"
                :disabled="invalid || invalidWeight || disabledBtn"
                class="ml-2"
              >
                {{ isCreated ? $t("save_changes") : $t("create_price_list") }}
              </v-btn>
            </v-layout>
          </v-layout>
        </v-form>
      </validation-observer>
      <!-- Dialog loading -->
      <dialog-loading :is-show="showLoading" :message="$t('processing')" />

      <!-- notifications -->
      <dialog-notification
        v-model="showNoti"
        :icon-src="getIcon"
        :message="message"
      />

      <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
    </v-layout>
  </v-container>
</template>

<script>
import Breadscrumbs from "@/components/base/Breadscrumbs";
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import HeaderPricePartner from "@/views/table_price/components/HeaderPricePartner";
import InputPricePartner from "@/views/table_price/components/InputPricePartner";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import mixinPricePartner from "@/views/table_price/mixins/mixinPricePartner";
import constants from "@/constants";
import { StorageService } from "@/services/storageService";
import { StringUtils } from "@/helpers/stringUtils";
import { PricesService } from "@/services/pricesService";
import { PostService } from "@/services/postService";
import { BaseService } from "@/services/baseService";
import { DataUtils } from "@/helpers/dataUtils";
import routePaths from "@/router/routePaths";

export default {
  components: {
    Breadscrumbs,
    DialogLoading,
    DialogNotification,
    HeaderPricePartner,
    DialogConfirmTokenExpried,
    InputPricePartner,
  },
  mixins: [mixinPricePartner],
  props: {
    listPrice: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    customerType: constants.customerType.shop,
    type: constants.pricesType.standard,
    name: "",
    maximumSmallText: constants.maximumSmallText,
    requiredRules: {
      required: true,
    },
    groupName: null,
    lstPartner: [],
    lstServicePartner: [],
    partnerSelect: null,
    serviceSelect: null,
    isDefaul: false,
    indexDefault: null,
    lstService: [],
    lstdata: [],
    errorMessage: "",
    detailIdPrice: null,
    rules: {
      empty: (name) => (v) => {
        if (!v?.length || !v?.replace(/\s/g, "").length) {
          return `${name} ${this.$t("users.required")}`;
        }
        return true;
      },
    },
    linkBack: null,
    checkIsDefault: true,
    currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
    breadcrumbs: [
      {
        text: "bảng giá shop",
        isActive: false,
        href: routePaths.SHOP_PRICE_LIST,
      },
      {
        text: "Sửa bảng giá",
        disabled: true,
        isActive: true,
      },
    ],
    lstPostOffice: [],
    selectPostOffice: null,
    postOfficeName: "",
    checkRoleDetail: null,
    detailPrice: null,
    disabledBtn: false,
  }),
  computed: {
    checkReadonly() {
      return this.$route.query.detailIdPrice
        ? this.checkIsDefault === true
        : this.checkIsDefault === false;
    },
  },
  async created() {
    this.detailIdPrice = this.$route.query.detailIdPrice;
    this.groupName = this.$route.query.groupName;
    await this.getShopPricesByName();
    if (
      (window.location.pathname && !this.currentRole.includes("ROLE_ADMIN")) ||
      this.checkRoleDetail === constants.statusCode.badRequest
    ) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, 2000);
    }
  },
  beforeMount() {
    this.initData();
    this.searchPostOffice();
  },
  methods: {
    async initData() {
      await this.getPartners();
      if (this.detailIdPrice) {
        this.isCreated = true;
        const results = await this.getShopPricesByName();
        if (
          results.customerPriceDtos !== null &&
          results.customerPriceDtos.length > 0
        ) {
          this.lstPriceOrigin = results.customerPriceDtos;
          this.name = results.name;
          this.partnerSelect = results.partnerId;
          this.onChangeGetServiceAll();
          this.serviceSelect = results.serviceId;
          this.selectPostOffice = results.poId;
          this.postOfficeName = results.poName;
          if (results.isDefault === 0) {
            this.isDefaul = false;
          } else {
            this.isDefaul = true;
          }
          await this.parseToLocalPrices(results.customerPriceDtos);
          this.autoIncrease = this.getHighestPriceId() + 1;
          this.checkInvalidWeight();
          this.bindMaxWeight();
        } else {
          this.$router.push(routePaths.SHOP_PRICE_LIST);
        }
      } else {
        this.isCreated = false;
        this.initItem();
      }
      if (this.detailIdPrice) {
        this.breadcrumbs[1].text = "Sửa bảng giá";
      } else {
        this.breadcrumbs[1].text = "Tạo bảng giá";
      }
    },
    async getPartners() {
      try {
        this.lstPartner = [];
        const { status, data } = await BaseService.getLogisticPartner();
        if (
          status === constants.statusCode.ok &&
          data !== null &&
          data.length > 0
        ) {
          this.lstServicePartner = data;
          this.lstPartner = data.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.partnerId === item.partnerId)
          );
          this.lstPartner.unshift({
            partnerId: null,
            partnerName: "Tất cả",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onChangeGetServiceAll() {
      try {
        this.lstService = this.lstServicePartner.filter(
          (it) => it.partnerId === this.partnerSelect
        );
      } catch (error) {
        console.log(error);
      }
    },
    async searchPostOffice() {
      this.showLoading = true;
      const pars = this.bindRequestPars();
      const { status, data } = await PostService.getPostOffice(pars);
      if (status === constants.statusCode.ok && data) {
        this.totalRecord = data.totalRecord;
        if (data.totalRecord > 0) {
          this.lstPostOffice = data.data;
        } else {
          this.lstPostOffice = [];
        }
        this.totalPage = DataUtils.calculateEmployeePage(this.totalRecord);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    bindRequestPars() {
      const pars = {
        keyword: "",
        status: 1,
        limit: 1000,
        currentPage: this.page,
      };
      return pars;
    },
    async getShopPricesByName() {
      if (this.detailIdPrice) {
        this.showLoading = true;
        const { status, data } = await PricesService.getCustomerPrices(
          this.detailIdPrice
        );
        this.showLoading = false;
        this.checkRoleDetail = status;
        if (status === constants.statusCode.ok && data) {
          this.lstdata = data;
          this.detailPrice = data;
          return data;
        } else if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true;
        }
      }
      return null;
    },
    async submit() {
      var valid = this.$refs.observer.validate();
      if (valid && !this.invalidWeight) {
        if (this.isCreated) {
          this.updatePrices();
        } else {
          this.createPrices();
        }
      }
    },
    async createPrices() {
      this.showLoading = true;
      this.disabledBtn = true;
      const pars = {
        poId: StorageService.getPostOfficeId(),
        partnerId: this.partnerSelect,
        serviceId: this.serviceSelect,
        name: this.name,
        isDefault: this.isDefaul
          ? constants.statusPartnerAccount.active
          : constants.statusPartnerAccount.inactive,
        customerPriceDtos: this.getRequestPars(),
      };
      if (this.selectPostOffice) {
        pars["poId"] = this.selectPostOffice;
      }
      const { status, data } = await PricesService.shopCreate(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t("create_success_prices"),
        });
        setTimeout(() => {
          this.$router.push(routePaths.SHOP_PRICE_LIST);
        }, constants.timeOut);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.$refs.namePrice.focus();
        this.toggleDialogNoti({ state: true, msg: data.message });
        setTimeout(() => {
          this.toggleDialogNoti();
        }, constants.timeOut);
        this.disabledBtn = false;
      }
    },
    getGroupName() {
      return this.name.trim();
    },
    getRequestPars() {
      const rqPars = [];
      if (this.lenLstPrice > 0) {
        const arrPrice = this.sortPricesByWeight();
        arrPrice.forEach((i, index) => {
          var fromWeight = 0;
          if (index > 1) {
            fromWeight = StringUtils.numStrToFloat(arrPrice[index - 1].weight);
          } else if (index === 1) {
            fromWeight = StringUtils.numStrToFloat(arrPrice[index - 1].weight);
          }
          const iOrigin = this.findItemOriginById(i.vId);
          const item = this.mappingRequestItem(i, fromWeight, iOrigin);
          rqPars.push(item);
        });

        if (this.lstDeleteOrigin.length > 0) {
          const arrDel = this.lstDeleteOrigin;
          arrDel.forEach((item) => {
            item.status = constants.status.del;
            item.hasUpdate = true;
            rqPars.push(item);
          });
        }
      }

      return rqPars;
    },
    mappingRequestItem(i, fromWeight, iOrigin) {
      const item = {};
      item["serviceId"] = constants.serviceType.normal;
      item["poId"] = StorageService.getPostOfficeId();
      item["type"] = this.type;
      item["customerType"] = this.customerType;
      item["transportType"] = i.transportType;
      item["fromWeight"] = fromWeight;
      item["toWeight"] = i.isMax ? null : StringUtils.numStrToFloat(i.weight);
      item["intracityPrice"] = StringUtils.numStrToFloat(i.intracityPrice);
      item["intracityAccumulatePrice"] = StringUtils.numStrToFloat(
        i.intracityAccumulatePrice
      );
      item["intrazonePrice"] = StringUtils.numStrToFloat(i.intrazonePrice);
      item["intrazoneAccumulatePrice"] = StringUtils.numStrToFloat(
        i.intrazoneAccumulatePrice
      );
      item["interzonePrice"] = StringUtils.numStrToFloat(i.interzonePrice);
      item["interzoneAccumulatePrice"] = StringUtils.numStrToFloat(
        i.interzoneAccumulatePrice
      );
      item["status"] = i.status;

      if (
        typeof iOrigin !== constants.undefined &&
        iOrigin !== null &&
        iOrigin.id > 0
      ) {
        item["id"] = iOrigin.id;
        item["groupId"] = iOrigin.groupId;
        item["groupName"] = this.getGroupName();
        if (this.isChangeGroupName(iOrigin.groupName)) {
          item["hasUpdate"] = true;
        } else {
          item["hasUpdate"] = this.checkItemChange(iOrigin, item, i.isMax);
        }
      } else {
        item["hasUpdate"] = false;
      }

      return item;
    },
    async updatePrices() {
      this.showLoading = true;
      this.disabledBtn = true;
      const pars = {
        poId: StorageService.getPostOfficeId(),
        partnerId: this.partnerSelect,
        serviceId: this.serviceSelect,
        name: this.name,
        isDefault: this.lstdata.isDefault,
        pricesGroupId: this.detailIdPrice,
        customerPriceDtos: this.getRequestPars(),
      };
      if (this.selectPostOffice) {
        pars["poId"] = this.selectPostOffice;
      }
      const { status, data } = await PricesService.shopUpdate(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t("success_saved_info"),
        });
        setTimeout(() => {
          this.$router.push(routePaths.SHOP_PRICE_LIST);
        }, constants.timeOut);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
        setTimeout(() => {
          this.toggleDialogNoti();
        }, constants.timeOut);
        this.disabledBtn = false;
      }
    },
    cancelData() {
      this.name = "";
      this.$router.push(routePaths.SHOP_PRICE_LIST);
    },
    isChangeGroupName(groupNameOrigin) {
      if (groupNameOrigin) {
        const name = this.getGroupName().toLowerCase();
        return name !== groupNameOrigin.toLowerCase();
      }
    },
  },
};
</script>

<style src="@/styles/createPriceShop.scss" lang="scss" >
</style>
